body {
  color: $color-primary;
}

h2 {
  font-size: 38px;
  margin-top: 0;
}

.heading {
  font-family: $header-font-family;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;

  &.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
}

.container {
  max-width: 1280px;
  padding: 40px 20px;
}

.button-group {
  display: flex;

  justify-content: space-around;
}

.button-primary {
  text-decoration: none;
  fill: $button-primary-color !important;
  background: $button-primary-background-color;
  color: $button-primary-color !important;
  border-radius: 3px;
  padding: 5px 8px;

  &:hover {
    background: $button-primary-background-color-hover;
    color: $button-primary-color-hover !important;
    fill: $button-primary-color-hover !important;
    text-decoration: none;
  }
}

.button-secondary {
  text-decoration: none;
  fill: $button-secondary-color;
  background: $button-secondary-background-color;
  color: $button-secondary-color;
  border-radius: 3px;
  padding: 5px 8px;

  &:hover {
    background: $button-secondary-background-color-hover;
    color: $button-secondary-color-hover;
    fill: $button-secondary-color-hover;
    text-decoration: none;
  }
}

@media only screen and (max-width: 970px) {
  .container {
    padding: 40px 15px;
  }
}
