.skills{
    background: $color-background;
    h2{
        color: $color-secondary;
    }
    .progress{
        background: $color-accent;
        width: 100%;
        height: 10px;
        border-radius: 1rem;
        position: relative;
        overflow: hidden;
    
        &:after{
            content: "";
            width: 100%;
            height: 100%;
            background: rgb(236,236,236);
            position: absolute;
 
        }
    }
    
    .card-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    .skill{
        padding: 10px 0;
        .skill-name{
            padding-bottom: 5px;
            .score{

            }
        }
    }
    @include process-generator
}

