.hero-banner{
    height: 100vh;
    background: $color-background;
    h1{
        font-size: 64px;
        margin-top: 0;
    }

    .container{
        position: relative;
        text-align: center;
        top: 24%;
        color: $color-secondary;
        padding: 0;
    }

    .downArrow{
        margin-top: 10%;

        h2{
            margin-bottom: 4%;
        }
    }

    .svg-icon{
        fill:$color-secondary;
    }

    .arrow-fade {
        -webkit-animation: arrow-fade 2s ease-in-out infinite;
        -moz-animation:    arrow-fade 2s ease-in-out infinite;
        animation:         arrow-fade 2s ease-in-out infinite;
    }
    
    @-webkit-keyframes arrow-fade {
      0%   { -webkit-transform:translate(0,-15px); opacity: 0;  }
      50%  { opacity: 1;  }
      100% { -webkit-transform:translate(0,20px); opacity: 0; }
    }
    @-moz-keyframes arrow-fade {
      0%   { -moz-transform:translate(0,-15px); opacity: 0;  }
      50%  { opacity: 1;  }
      100% { -moz-transform:translate(0,20px); opacity: 0; }
    }
    @keyframes arrow-fade {
      0%   { transform:translate(0,-15px); opacity: 0;  }
      50%  { opacity: 1;  }
      100% { transform:translate(0,20px); opacity: 0; }
    }
}

@media only screen and (max-width:970px){
    .hero-banner{
        padding: 0 12px;
        
    }
}
